import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

export const CardTable = styled(Container)`
    width: 100%;
    margin: ${(props) => props.margin};
    max-width: 100% !important;
`;

export const CardTableRow = styled(Row)`
    margin: 0 !important;
    display: flex;
    align-items: ${(props) => props.alginitems || "center"};
    justify-content: ${(props) => props.justifycontent || "center"};
    gap: ${(props) => props.gap || "50px 8px"};
`;

export const CardTableCol = styled(Col)`
    ${({ autoWidth, mobile }) => (autoWidth ? "" : `width: ${mobile ? `160px !important` : `243px !important`};`)}
    height: ${(props) => props.height};
    padding: 0px !important;
    background-color: #fff;
`;

export const CardIconWrapper = styled.div`
    width: ${(props) => props.width || "70px"};
    height: ${(props) => props.height || "70px"};
    margin: 0 auto;
    padding: 0 0 12px;
`;

export const CardTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${(props) => props.margin};

    font-size: ${(props) => props.fontSize};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: center;
    color: #1e1e1e;
    white-space: pre-line;
`;

export const CardDesc = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: center;
    color: #1e1e1e;
    white-space: pre-line;
`;
