import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box, Wrapper } from "components/style/layoutStyle";
import { HighlightText } from "components/highlightText";
import AccordionButton from "components/button/accordionButton";
import { FaqTableBox, FaqTableWrapper, FaqTableRow, FaqTableCell, FaqBox, Content } from "./style";
import Pagination from "components/navigation/pagination";

const FaqTable = ({ faqData, category, keyword, searchResult }) => {
    const { isMobile } = ResponsiveMedia();
    const [page, setPage] = useState(1);

    const handleChangePage = (event) => {
        setPage(event);
    };

    useEffect(() => {
        setPage(1);
    }, [category]);

    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = useState(false);

        return (
            <React.Fragment>
                <FaqTableWrapper open={open} mobile={isMobile} borderTop={isMobile && index === 0 && "none !important"}>
                    <FaqTableRow onClick={() => setOpen(!open)}>
                        <FaqTableCell flex={2}>
                            <Content>
                                <HighlightText bold text={"[" + row.category + "]"} keyword={keyword} />
                                &nbsp;
                                <HighlightText bold={open} text={row.title} keyword={keyword} />
                            </Content>
                        </FaqTableCell>
                        <FaqTableCell>
                            <AccordionButton open={open} />
                        </FaqTableCell>
                    </FaqTableRow>
                    <FaqBox open={open} mobile={isMobile}>
                        <FaqTableCell style={{ margin: isMobile === "true" ? "0 20px 20px 0" : "0 36px 20px 0" }} colSpan={2}>
                            <Content>
                                <HighlightText text={row.desc} keyword={keyword} />
                            </Content>
                        </FaqTableCell>
                    </FaqBox>
                </FaqTableWrapper>
            </React.Fragment>
        );
    }

    return (
        <Container padding={isMobile === "true" ? "0" : "0 20px 0"}>
            <Box flexDirection={"column"}>
                <FaqTableBox>
                    {faqData &&
                        faqData.slice((page - 1) * 10, searchResult ? page * 5 : page * 10).map((row, index) => {
                            return <Row key={index} row={row.node} index={index} />;
                        })}
                </FaqTableBox>
                {faqData && !searchResult && (
                    <Wrapper margin={"68px 0 120px"}>
                        <Pagination data={faqData} onClickEvent={handleChangePage} />
                    </Wrapper>
                )}
            </Box>
        </Container>
    );
};

export default connect(
    (state) => ({
        keyword: state.support.search_keyword,
        faqData: state.support.faq_data
    }),
    null
)(FaqTable);
