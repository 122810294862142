import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";

import { CardTable, CardTableRow, CardTableCol, CardTitle, CardDesc, CardIconWrapper } from "./style";
import { useTranslation } from "react-i18next";

const Card = ({ data, autoWidth, height, colCount, iconSize, titleSize, descSize, justifyContent, alginitems, gap }) => {
    const { isMobile } = ResponsiveMedia();
    const { t } = useTranslation();

    return (
        <CardTable margin={isMobile === "true" ? "56px 0 90px" : "80px 0px 40px"}>
            <CardTableRow xs={colCount} justifycontent={justifyContent} alginitems={alginitems} gap={gap}>
                {data.map((item, index) => {
                    return (
                        <CardTableCol key={index} mobile={isMobile} height={height} autoWidth={autoWidth}>
                            {/* iconSize의 기본값은 70 x 70 */}
                            <CardIconWrapper width={iconSize} height={iconSize}>
                                <Image filename={item.img} alt={t(item.title)} />
                            </CardIconWrapper>
                            <CardTitle fontSize={isMobile === "true" ? "16px" : titleSize || "20px"} margin={isMobile === "true" ? "12px 0 8px" : "12px 0 12px"}>
                                {t(item.title)}
                            </CardTitle>
                            <CardDesc fontSize={isMobile === "true" ? "14px" : descSize || "16px"}>{isMobile === "true" && t(item.desc_mobile) ? t(item.desc_mobile) : t(item.desc)}</CardDesc>
                        </CardTableCol>
                    );
                })}
            </CardTableRow>
        </CardTable>
    );
};

export default Card;
